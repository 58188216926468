@import "vars";

html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
    border: none;
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */
html,
body,
.site-body,
main,
#content {
    position: relative;
    width: 100%;
    min-height: 100vh;
}
body {
    padding:0;
    margin:0;
    font-size:16px;
}

img {
    border:none;
}

/* ==========================================================================
   Holding Page
   ========================================================================== */

#content {
    position: relative;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    .tile {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-repeat: repeat;
        background-size: 550px;
        opacity: 0.2;
    }
}
.contentwrap {
    margin: 0 auto;
    position:relative;
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-bottom: 60px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    z-index: 1;
}
.flex {
    display: flex;
    flex: 1; /* fill contentwrap */
    justify-content: center;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    .col {
        text-align: center;
        flex-basis: 50%;
        flex-grow: 1;
        padding: 0px 5%;
        max-width: 700px;
        box-sizing: border-box;
        margin-right: auto;
        &.first {
            margin-right: 0px;
            margin-left: auto;
        }
    }
    .line {
        position: relative;
        width: 1px;
        padding-top: 30%;
        background-color: $hr;
        margin: 0px auto;
    }
}
.logo {
    display: block;
    width: 100%;
    height:auto;
    h1 {
        margin: 0px;
    }
}

/** contact details **/
.contact-details {
    padding: 30px 0px;
    border-top: 1px solid $hr;
    border-bottom: 1px solid $hr;
    margin: 5px 0px;
    a {
        text-decoration: none;
        color: inherit;
    }
    p {
        margin: 5px 0;
    }
}
.opening-hours {
    display: block;
}

/** site links **/
.site-links {
    margin-top: 3px;
}
.site-link {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 4px;
    box-sizing: border-box;
    margin: 2px;
    svg {
        position: relative;
        width: auto;
        max-height: 100%;
        path {
            fill: $primary;
            transition: fill 0.3s ease;
        }
        &:hover {
            path {
                fill: $secondary;
            }
        }
    }
}

/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
    p {
        position: relative;
        margin: 10px 0;
    }
    button {
        font-size: 18px;
        background-color: transparent;
        border: none;
        color: $primary;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;
        padding: 10px 5px;
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;
        transition: color 0.3s ease;
        &:hover {
            color: $secondary;
        }
    }
}
form
label.float,
input {
    height: 45px;
    line-height: 45px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font;
    font-size: 16px;
    width: 100%;
    color: #12171f;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid $primary;
    transition: border-color 0.3s ease;
    &:focused {
        border-color: $secondary;
    }
}
textarea {
    padding-top: 10px;
    min-height: 100px;
}
label.float {
    position: absolute;
    left:0;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.float-wrap {
    &.focused label.float,
    &.populated label.float {
        left: 5px;
        padding-left: 0;
        padding-top: 5px;
        font-size: 0.75em;
    }
}
.recaptcha-notice {
    color: $hr;
    a {
        color: inherit;
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (min-width: 2500px){
    .flex .col {
        max-width: 900px;
    }
}

@media only screen and (max-width: 1200px){
    #content {
        min-height: 100%;
    }
    .flex {
        display: block;
        .col {
            margin: 0 auto;
            &.first {
                margin: 0 auto;
            }
        }
        .line {
            height: 1px;
            width: 700px;
            width: calc(700px - 10%);
            padding: 0px;
            box-sizing: border-box;
            max-width: 100%;
            margin: 20px auto;
        }
    }
}

@media only screen and (max-width: 760px) {
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
    .flex {
        .col {
            width: 100%;
            max-width: 100%;
        }
    }
}
@media only screen and (max-width: 500px){
    .flex .col {
        padding: 0px;
        &.first {
            padding-bottom: 20px;
        }
    }
}

@import "helpers";
@import "print";